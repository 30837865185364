/* LatestArrivals.css */
.latest-arrivals {
    width: 100%;
    max-width: 100%;
}

.product-slide {
    position: relative;
    text-align: center;
}

.product-image {
    width: 100%;
    height: auto;
}

.product-info {
    padding: 10px;
    background-color: #fff;
}

.product-title {
    font-size: 1.2em;
    margin: 0;
}

.product-price {
    font-size: 1.1em;
    color: #333;
}