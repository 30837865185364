/* Center the modal on the screen */
.video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    /* Ensure it's above other elements */
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    /* Scrollable if content is too long */
}

/* Style the overlay to cover the entire screen */
.video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dark semi-transparent background */
    z-index: 1000;
    /* Ensure it's below the modal */
}

/* Style the close button */
.video-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #000;
    transition: color 0.3s;
}

.video-modal-close-button:hover {
    color: #f00;
}

/* Style the video element */
.video-modal-video {
    width: 100%;
    height: auto;
    border-radius: 8px;
}